import { TSubmitRequestPayload } from "..";
import { BaseRmaRequest, TRMAItem } from "../rma_request_types";
import { SfyDiscountApplicationType, SfyLineItem, SfyOrderType } from "../sfy_order_types";

type DerivedProjectedLineItem = {
  orderId?: number;
  /** The item this is exchange for. It being undefined means it's a root item */
  parentLineItemId?: number;
  id?: number;
} & Pick<SfyLineItem, "price" | "productId" | "variantId">;

type DerivedConfirmedLineItem = DerivedProjectedLineItem &
  SfyLineItem & {
    discounts: SfyDiscountApplicationType[];
  };

export type DerivedLineItem = DerivedProjectedLineItem | DerivedConfirmedLineItem;

export type DerivedOrderState = {
  /** First-to-last */
  pastOrderIds: number[];
  seenTags: string[];
  lineItems: DerivedLineItem[];
  nthExchange: number;
  totalPrice: number;
};

export const deriveRootOrder = (order: SfyOrderType): DerivedOrderState => ({
  pastOrderIds: [order.id],
  seenTags: order.tags.split(","),
  // Explode lineItems into an array of lineItems based on quantity
  lineItems: order.lineItems.reduce<DerivedLineItem[]>((acc, item) => {
    const explodedLineItems = Array.from({ length: item.quantity }).map<DerivedLineItem>(
      (): DerivedConfirmedLineItem => {
        const itemDiscountIndexes = item.discountAllocations.map((d) => d.discountApplicationIndex);
        const itemDiscounts = order.discountApplications.filter((_, i) =>
          itemDiscountIndexes.includes(i),
        );

        return {
          orderId: order.id,
          discounts: itemDiscounts,
          ...item,
        };
      },
    );

    return [...acc, ...explodedLineItems];
  }, []),
  nthExchange: 0,
  totalPrice: Number(order.totalPrice),
});

export const deriveOrder = <T>(
  order: DerivedOrderState,
  rmaRequest: BaseRmaRequest<T> | TSubmitRequestPayload["rma_items"],
  exchangeOrder?: SfyOrderType,
): DerivedOrderState => {
  let removedLineItemIds: number[] = [];

  if (Array.isArray(rmaRequest)) {
    removedLineItemIds = rmaRequest?.filter((i) => i.type === "Return").map((i) => i.line_item_id);
  } else {
    removedLineItemIds = (rmaRequest.rmaItems ?? [])
      .filter((i) => i.type === "return")
      .map((i) => i.lineItemId)
      .filter((i): i is number => typeof i === "number");
  }

  const remainingLineItems = removeLineItems(order.lineItems, removedLineItemIds);
  const derivedLineItems = exchangeLineItems<T>(
    remainingLineItems,
    rmaRequest,
    order.pastOrderIds[order.pastOrderIds.length - 1],
    exchangeOrder,
  );

  const allOrderIds =
    "exchangeOrderId" in rmaRequest && rmaRequest.exchangeOrderId
      ? [...order.pastOrderIds, rmaRequest.exchangeOrderId]
      : order.pastOrderIds;

  return {
    pastOrderIds: allOrderIds,
    seenTags: [...order.seenTags, ...("tags" in rmaRequest ? rmaRequest.tags ?? [] : [])],
    lineItems: derivedLineItems,
    nthExchange: order.nthExchange + 1,
    totalPrice: derivedLineItems.reduce((acc, item) => acc + Number(item.price), 0),
  };
};

const removeLineItems = (
  lineItems: DerivedLineItem[],
  removedLineItemIds: number[],
): DerivedLineItem[] => {
  const todoArray = [...removedLineItemIds];
  const remainingLineItems = lineItems.filter((lineItem) => {
    const index = todoArray.findIndex((lineItemId) => lineItemId === lineItem.id);

    if (index === -1) {
      return true;
    }

    // Remove item from array. This is needed for cases where quantity of line item > 1.
    todoArray.splice(index, 1);

    return false;
  });

  const removedLineItemsCount = lineItems.length - remainingLineItems.length;

  if (removedLineItemsCount !== removedLineItemIds.length) {
    throw new Error(
      `removedLineItemsCount !== removedLineItems.length: ${removedLineItemsCount} !== ${removedLineItemIds.length}`,
    );
  }

  return remainingLineItems;
};

const exchangeLineItems = <T>(
  lineItems: DerivedLineItem[],
  rmaRequest: BaseRmaRequest<T> | TSubmitRequestPayload["rma_items"],
  originalOrderId: number,
  exchangeOrder?: SfyOrderType,
): DerivedLineItem[] => {
  const exchangeOrderId = (!Array.isArray(rmaRequest) && rmaRequest.exchangeOrderId) || undefined;

  let tempExchangeRmaItems: TRMAItem[];

  if (Array.isArray(rmaRequest)) {
    tempExchangeRmaItems = rmaRequest
      .filter((i) => i.type === "Exchange")
      .map((i) => ({
        lineItemId: i.line_item_id,
        originalProductId: i.original.product_id,
        originalVariantId: i.original.variant_id,
        exchangeProductId: i.exchange?.product_id,
        exchangeVariantId: i.exchange?.variant_id,
        exchangePrice: i.exchange?.price,
      }));
  } else {
    tempExchangeRmaItems = rmaRequest.rmaItems?.filter((i) => i.type === "exchange") ?? [];
  }

  const exchangedLineItems = lineItems.map((lineItem): DerivedLineItem => {
    const exchangedRmaItemIndex = tempExchangeRmaItems.findIndex(
      (exchangeItem) => exchangeItem.lineItemId === lineItem.id,
    );

    if (exchangedRmaItemIndex === -1) {
      return lineItem;
    }

    const lineItemId = (lineItem as { id: number }).id; // We already confirmed a few lines above 'id' is defined

    const exchangedRmaItem = tempExchangeRmaItems[exchangedRmaItemIndex];

    // Remove the item from the temp array
    tempExchangeRmaItems.splice(exchangedRmaItemIndex, 1);

    if (!exchangeOrder) {
      const { exchangeLineItemId, exchangeProductId, exchangeVariantId } = exchangedRmaItem;

      if (!exchangeProductId || !exchangeVariantId) {
        throw new Error(
          `Failed to find exchange line item info (pid: ${exchangeProductId}, vid: ${exchangeVariantId}) on rmaItem ${exchangedRmaItem.lineItemId} for order ${originalOrderId}`,
        );
      }

      const returnValue: DerivedLineItem = {
        orderId: exchangeOrderId,
        id: exchangeLineItemId,
        price: String(exchangedRmaItem.exchangePrice),
        parentLineItemId: lineItemId,
        productId: exchangeProductId,
        variantId: exchangeVariantId,
      };

      return returnValue;
    }

    const exchangeLineItem = exchangeOrder.lineItems.find(
      (element) => element.id === exchangedRmaItem.exchangeLineItemId,
    );

    if (!exchangeLineItem) {
      throw new Error(
        `Failed to find exchange line item ${exchangedRmaItem.lineItemId} for order ${exchangeOrder.id}`,
      );
    }

    return {
      orderId: exchangeOrderId,
      parentLineItemId: lineItemId,
      ...exchangeLineItem,
    };
  });

  if (tempExchangeRmaItems.length !== 0) {
    throw new Error(`tempExchangeRmaItems.length !== 0: ${tempExchangeRmaItems.length} !== 0`);
  }

  return exchangedLineItems;
};
